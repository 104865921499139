.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mask-image: url('../../../../../../../public/assets/images/Vector1.png');
  mask-size: cover;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-composite: source-over;
  mask-mode: luminance;
}

.video-overlay {
  width: 100%;
  height: 100%;
  object-fit: cover;
  mask-image: url('../../../../../../../public/assets/images/Vector1.png');
  mask-size: cover;
  mask-repeat: no-repeat;
}

.mask-image {
  display: none;
}

.gradient-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(12, 12, 12, 0.5) 0%,
    rgba(12, 12, 12, 0.2525) 50.5%,
    rgba(12, 12, 12, 0.5) 100%
  );
  background-blend-mode: color;
  background: rgba(12, 12, 12, 1);
  background: linear-gradient(357.06deg, rgba(12, 12, 12, 0) -0.17%, #0c0c0c 86.81%);
  /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); */
  z-index: 1;
  pointer-events: none;
}
